import React from "react"
import { Link } from "gatsby"

const MeetTheHostNav = () => (
  <div className="side-navigation">
    <div className="nav-item">
      <Link to="/meet-the-host" activeClassName="active">
        Meet the Host
      </Link>
    </div>
    <div className="nav-item">
      <Link to="/meet-the-executive-producer" activeClassName="active">
        Meet the Executive Producer
      </Link>
    </div>
    <div className="nav-item">
      <Link to="/meet-the-senior-producer" activeClassName="active">
        Meet the Senior Producer
      </Link>
    </div>
  </div>
)

export default MeetTheHostNav
