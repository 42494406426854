import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";
import MeetTheHostNav from "../components/meet-the-host-nav";

const MeetTheHostPage = ({ data }) => (
  <Layout>
    <SEO
      title="The Kalb Report | Meet the Executive Producer"
      keywords={[`kalb`, `kalb report`, `the kalb report`]}
    />
    <section className="small-page-heading">
      <div className="container">
        <h1>Meet the Executive Producer</h1>
      </div>
    </section>
    <section className="section bg-gray about-the-program-section" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <MeetTheHostNav />
          </div>
          <div className="col-lg-8">
            <Img
              className="bio-picture"
              fluid={data.mikeFreedman.childImageSharp.fluid}
              fadeIn={false}
            />
            <p>
              In a distinguished career spanning more than 35 years, Freedman
              has served as general manager of CBS Radio Network News, managing
              editor for the Broadcast Division of United Press International
              (UPI), vice president and professor of journalism at The George
              Washington University, senior vice president and journalist in
              residence at University of Maryland Global Campus, and president
              of the National Press Club.
            </p>
            <p>
              For the past 26 years, he has also served as executive producer of
              the multi-awarding winning <em>Kalb Report</em> public
              broadcasting series, based at the National Press Club and
              moderated by legendary journalist Marvin Kalb. In addition, he is
              executive producer of the weekly radio series “This Just In!”
              which explores the past, present and future of journalism.
            </p>
            <p>
              During his tenure at CBS News, Freedman partnered with Walter
              Cronkite on the legendary newsman’s final programming at the
              network, including the 30-part radio series “Walter Cronkite’s
              Postscripts to the 20th Century.” He was also executive producer
              of the three-hour
              <em>CBS News 20th Century Roundup</em> anchored by Dan Rather and
              featuring the final reunion of all of the surviving members of
              Edward R. Murrow’s original broadcast team from World War II.
            </p>
            <p>
              At GW, Freedman forged groundbreaking partnerships with CNN, The
              National Press Club, POLITICO, and The Newseum and led the
              university’s participation in the production of nearly 800{" "}
              <em>CNN Crossfire</em> telecasts live from campus. At United Press
              International, Freedman led the UPI Radio Network and the National
              Broadcast Wire. Prior to joining UPI, Freedman spent 15 years as a
              national award winning reporter, anchor, sportscaster, and news
              director in Detroit radio.
            </p>
            <p>
              Freedman and his teams have earned more than 85 honors including
              14 Edward R. Murrow awards. He is co-author of{" "}
              <em>The Broadcast Voice Handbook</em> and a contributing author to
              <em>Broadcasting Through Crisis</em>,{" "}
              <em>Responsible Journalism</em> and{" "}
              <em>The Encyclopedia of Journalism</em>.
            </p>
          </div>
          <div className="col-lg-3 offset-lg-1" />
        </div>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query {
    mikeFreedman: file(relativePath: { eq: "mike_freedman.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MeetTheHostPage;
